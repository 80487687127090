import _ from 'lodash';
import Alpine from 'alpinejs'
import collapse from '@alpinejs/collapse'
import jQuery from 'jquery';
import Popper from 'popper.js';

Alpine.plugin(collapse)

window.Alpine = Alpine;

// Bind vendor libraries to window...
window._ = _;
window.$ = window.jQuery = jQuery;
window.Popper = Popper;

// Vendor Components...
import 'bootstrap';

Alpine.start();

// Vendor components...
import './vendor/distortedfusion/laravel-tailwind-layout/dropdown-placement.js';
import './vendor/distortedfusion/laravel-tailwind-layout/js-detection.js';
import './vendor/distortedfusion/laravel-tailwind-layout/sidebar.js';
import './vendor/distortedfusion/laravel-tailwind-layout/tooltip.js';
